<template>
    <article class="member member-min">
        <div class="tile plain">

            <div class="article--intro">

                <div class="col2">
                    <h4 class="article--title">{{ name }}</h4>
                </div>
            </div>
            
            <div class="article--content">
                <div class="article--teaser teaser"> 
                    <p>{{ email }}</p>
                </div>
            </div>

        </div>
    </article>
</template>

<script>
export default {
    name: 'member-card-min-external-email',
    props: {
        memberData: Object,
        name: String,
        email: String
    }
}
</script>

<style lang="scss" scoped>
</style>
