<template>
  <div class="page-layout detail-page details">

    <HeaderIntroImage backstep :headerMetadata="pageData"></HeaderIntroImage>

    <div class="container" v-if="!articleData">
      <div class="step">
        <br>
        <h3>Loading Data ...</h3>
        <br>
      </div>
    </div>

    <div v-if="articleData" class="container">

      <div class="step">
        <div v-if="articleData.attributes.field_internship_opportunity" class="pinned">
          internship opportunity
        </div>

        <div class="field-container">
          <p :class="['detail--tag', 'blue', 'pct', 'icon-left', 'target'+getTargetCode(level.attributes.name)]"
            v-for="(level, ind) in articleData.field_level" :key="'level'+ind">{{ level.attributes.name }}
          </p>
        </div>

        <h2 class="title">{{ articleData.attributes.title }}</h2>

        <div class="info-grid">
          <div class="cell" v-if="articleData.field_category">
            <p class="label">TYPE</p>
            <p class="blue"><span v-if="articleData.attributes.field_online_activity" class="bold">ONLINE</span>
              {{ articleData.field_category.attributes.name }}</p>
          </div>

          <div class="cell" v-if="articleData.field_program_language.length">
            <p class="label">LANGUAGES</p>
            <span class="blue comma" v-for="(l, index) in this.articleData.field_program_language"
              :key="'lang'+index">{{l.attributes.name}}</span>
          </div>

          <div class="cell">
            <p class="label">COUNTRIES</p>
            <keep-alive v-if="articleData.attributes.field_country.length">
              <div v-for="(c, ind) in articleData.attributes.field_country" :key="'org'+ind">
                <p v-if="c" class="blue bold">{{ organizationCountry(c.country) }}</p>
              </div>
            </keep-alive>
            <keep-alive v-else>
              <p class="blue bold">Worldwide</p>
            </keep-alive>
          </div>
        </div>

        <div class="content">
          <p v-if="articleData.attributes.field_summary_and_description"
            v-html="articleData.attributes.field_summary_and_description.value"></p>

          <p v-if="articleData.attributes.field_program_goal" class="label label-title">GOALS</p>
          <p v-if="articleData.attributes.field_program_goal" v-html="articleData.attributes.field_program_goal.value">
          </p>

          <div v-if="evaluationStatus && articleData.attributes.field_evaluation_references">
            <div class="evaluation-status">
              <p>{{ evaluationStatus }}</p>
            </div>
            <div class="evaluation-references">
              <p class="label-title">EVALUATION REFERENCES</p>
              <p v-html="this.articleData.attributes.field_evaluation_references.value"></p>
            </div>
          </div>
        </div>

        <div class="attachments-grid">
          <div class="box-row">
            <div class="box">
              <p class="label">TARGETS</p>
              <p v-for="(t, ind) in this.articleData.field_school_target" :key="'target-school-'+ind"
                class="detail--tag">
                <span v-if="t.attributes.name!='Other (specify)'"
                  class="blue bold icon-left target">{{ t.attributes.name }}</span>
              </p>

              <p v-for="(t, ind) in this.articleData.field_higher_education_target" :key="'target-high-'+ind"
                class="detail--tag">
                <span v-if="t.attributes.name!='Other (specify)'"
                  class="blue bold icon-left target">{{ t.attributes.name }}</span>
              </p>

              <p v-for="(t, ind) in this.articleData.field_life_long_learning_target" :key="'target-lifelong-'+ind"
                class="detail--tag">
                <span v-if="t.attributes.name!='Other (specify)'"
                  class="blue bold icon-left target">{{ t.attributes.name }}</span>
              </p>

              <p v-for="(t, ind) in this.articleData.field_outreach_target" :key="'target-outreach-'+ind"
                class="detail--tag">
                <span v-if="t.attributes.name!='Other (specify)'"
                  class="blue bold icon-left target">{{ t.attributes.name }}</span>
              </p>
            </div>

          </div>

          <div class="box-row">
            <div class="">
              <p class="label">PROVIDED BY</p>
              <div v-for="(org, ind) in articleData.field_program_organization" :key="'org'+ind">
                <p class="blue" style="margin: 0;">
                  <span class="bold">{{ org.attributes.field_org_name }}</span>{{
                    ((org.attributes.field_org_mat_prg_city && org.attributes.field_org_name) ? ', ' : '') + 
                    (org.attributes.field_org_mat_prg_city ? org.attributes.field_org_mat_prg_city : '') +
                    ((org.attributes.field_org_mat_prg_city || org.attributes.field_org_mat_prg_country) ? ', ' : '') + 
                    (org.attributes.field_org_mat_prg_country ? organizationCountry(org.attributes.field_org_mat_prg_country.country) : '')
                  }}
                </p>
              </div>
            </div>
          </div>

          <div class="box-row">
            <div class="topics" v-if="articleData.field_program_topic.length">
              <p class="label">TOPICS</p>
              <p v-for="(topic, ind) in this.articleData.field_program_topic.filter(topic=>topic!=undefined)"
                :key="'target'+ind" class="item">{{ topic.attributes.name }}</p>
            </div>
          </div>

        </div>
      </div>

      <aside class="contacts">

        <div class="people-row" v-if="contactPersonsLength">
          <p class="label">CONTACT PERSON</p>
          <ArticlesGrid class="slim">
            <MemberCardMin v-for="(p, index) in this.articleData.field_ref_member_2" :key="'contact'+index"
              :memberData="p" :routesData="routesData" />
            <MemberCardMinExternalEmail v-for="(a, ind) in articleData.attributes.field_contact_person_external"
              :key="'ext_contact'+ind" :memberData="a" :name="a.first" :email="a.second" />
          </ArticlesGrid>
        </div>
        
         <div class="people-row" v-if="articleData.attributes.field_multiple_email.length">
            <p class="label">CONTACT EMAIL</p>
            <div v-for="(m, index) in articleData.attributes.field_multiple_email" :key="index">
              <a :href="'mailto:'+m">{{m}}</a>
            </div>
          </div>

        <div class="box links">
          <a v-if="articleData.attributes.field_url" :href="articleData.attributes.field_url.uri" target="_blank"
            class="btn plain slim border">
            <span class="icon-right external">Go to website</span>
          </a>
        </div>
        
      </aside>

    </div>
  </div>
</template>

<script>
  import HeaderIntroImage from '../../components/layout-blocks/HeaderIntroImage.vue'
  import MemberCardMin from '../../components/layout-blocks/MemberCardMin.vue'
  import ArticlesGrid from '../../components/layout-blocks/ArticlesGrid.vue'
  import MemberCardMinExternalEmail from '../../components/layout-blocks/MemberCardMinExternalEmail.vue'
  import {
    fetchNodes
  } from '../../libs/drupalClient'
  export default {
    name: 'course',
    components: {
      HeaderIntroImage,
      MemberCardMin,
      ArticlesGrid,
      MemberCardMinExternalEmail
    },
    props: {},
    data: () => {
      return {
        pageData: {
          attributes: {
            title: "",
            subtitle: ""
          }
        },
        articleData: null,
      }
    },
    computed: {
      countries_multiple_field() {
        let countries = [];
        this.articleData.attributes.field_country.forEach(country => {
          let single_country = ''
          if (country) {
            single_country = this.$store.getters.getCountry(country.country)
            countries.push(single_country)
          }
        });
        return countries
      },
      evaluationStatus() {
        if (this.articleData.attributes.field_evaluation_status == undefined) {
          return false
        } else if (this.articleData.attributes.field_evaluation_status == 1) {
          return "Scientific evaluation"
        } else if (this.articleData.attributes.field_evaluation_status == 2) {
          return "Informal evaluation"
        } else {
          return false
        }
      },
      lang() {
        return this.$store.state.currentLang
      },
      defaultLang() {
        return this.$store.state.defaultLang
      },
      routesData() {
        return this.$store.state.routesData
      },
      objecttitle() {
        return this.$route.params.title
      },
      objectnid() {
        var link = this.routesData.filter(r => r.url_check == this.objecttitle)

        if (this.routesData.length <= 0) {
          return ''
        } else {
          if (link.length > 0)
            return link[0].nid
          else
            return '/not-found'
        }
      },
      categoryData() {
        var cd = {
          nid: 0,
          name: ""
        }

        switch (this.articleData.field_level[0].attributes.name) {
          case 'Primary and Secondary School':
            cd.name = "Primary and Secondary School"
            cd.nid = 14
            break;
          case 'Higher education':
            cd.name = "Higher Education"
            cd.nid = 15
            break;
          case 'Lifelong learning and (re)training':
            cd.name = "Lifelong Learning and (re)training"
            cd.nid = 16
            break;
          case 'Outreach':
            cd.name = "Outreach"
            cd.nid = 17
            break;
          default:
            cd.name = ""
            cd.nid = 0
            break;
        }

        return cd
      },
      loggedUser() {
        return this.$store.getters.getLogged
      },
      contactPersonsLength() {
        if(this.loggedUser)
          return this.articleData.field_ref_member_2.length + this.articleData.attributes.field_contact_person_external.length
        else
          return this.articleData.field_ref_member_2.filter(m => 
            (m.attributes.field_member_data_visibility && m.attributes.field_member_data_visibility!='None')).length 
            + this.articleData.attributes.field_contact_person_external.length
      }
    },
    methods: {
      getTargetCode(name) {
        switch (name) {
          case 'Primary and Secondary School':
            return 14;
          case 'Higher education':
            return 15;
          case 'Lifelong learning and (re)training':
            return 16;
          case 'Outreach':
            return 17;
          default:
            return 0;
        }
      },
      organizationCountry(code) {
        return this.$store.getters.getCountry(code)
      },
      orderMembers(array) {
        let first = array.filter(m => m.attributes.field_coordinator_type == '1')
          .sort((a, b) => {
            return a.attributes.field_last_name.localeCompare(b.attributes.field_last_name)
          })
        //.sort((a,b) => { return a.attributes.field_last_name > b.attributes.field_last_name ? 1 : -1 })

        let second = array.filter(m => m.attributes.field_coordinator_type == '2')
          .sort((a, b) => {
            return a.attributes.field_last_name.localeCompare(b.attributes.field_last_name)
          })
        //.sort((a,b) => { return a.attributes.field_last_name > b.attributes.field_last_name ? 1 : -1 })

        let third = array.filter(m => m.attributes.field_coordinator_type == null)
          .sort((a, b) => {
            return a.attributes.field_last_name.localeCompare(b.attributes.field_last_name)
          })
        //.sort((a,b) => { return a.attributes.field_last_name > b.attributes.field_last_name ? 1 : -1 })

        return first.concat(second).concat(third)
      },
      async findCT() {
        if (this.objectnid != '' && this.objectnid != '/not-found') {

          await fetchNodes('program', {
              include: [
                'field_level', 'field_program_organization', 'field_category', 'field_program_language',
                'field_program_topic', 'field_school_target', 'field_higher_education_target',
                'field_life_long_learning_target', 'field_ref_member_2'
              ],
              nested_include: [
                'field_ref_member_2.field_organization', 'field_ref_member_2.field_photo'
              ],
              filters: [{
                key: 'drupal_internal__nid',
                value: this.objectnid
              }]
            })
            .then(res => {
              this.articleData = res[0]
              this.articleData.field_ref_member_2 = this.articleData.field_ref_member_2
                .filter(m => m.attributes.field_member_data_visibility != 'None')

              //this.articleData.field_ref_member = this.orderMembers(this.articleData.field_ref_member)
              //console.log('program', this.articleData)
            })

        } else if (this.objectnid == '/not-found') {
          this.$router.push(this.objectnid)
        }
      }
    },
    async mounted() {
      await this.findCT()
      //console.log('articleData', this.articleData);
    },
    watch: {
      async objectnid() {
        await this.findCT()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../styles/variables.scss";

  .comma {
    &:after {
      content: ",";
      margin-right: 5px;
    }

    &:first-child:after,
    &:last-child:after {
      display: none;
    }

  }

  .pinned {
    margin-bottom: 2rem;
    text-transform: uppercase;
    padding-top: 8px;
    font-size: 14px;
    position: relative;
    padding-left: 2rem;

    &:after {
      position: absolute;
      left: 0em;
      top: 0em;
      background-image: $vgradient;
      width: 23px;
      height: 32px;
      clip-path: polygon(0% 0%, 0% 100%, 50% 75%, 100% 100%, 100% 0%);
      content: '';
      display: block;
    }
  }

  .author {
    margin: 1em 0;

    p {
      margin: .5em 0;
    }
  }

  .topics {
    
    .item {
      border: 2px solid #d1d2db;
      display: inline-block;
      padding: 4px 20px;
      margin-right: 6px;
      font-size: 14px;
      margin-top: 0;
      border-radius: 20px;
    }
  }

  .evaluation-status {

    .label {
      margin-bottom: 0.5rem;
    }

    .item {
      margin-top: 0;
      color: #071152;
      font-weight: 600;
    }

  }

  @media (min-width:1024px) {
    .field-container {
      display: flex;
      justify-content: space-between;

      p {
        font-size: 0.875rem;
        line-height: 1.3rem;
      }

      .icon-left:before {
        transform: none;
        top: 0;

      }
    }
  }

  @media (min-width:1200px) {
    .field-container {
      p {
        font-size: 16px;
        line-height: 1.3rem;
      }
    }
  }
</style>